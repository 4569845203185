import React from 'react';
import Fade from 'react-reveal/Fade';
import * as styles from '../styles/salaryResults.module.scss';
import { Typography } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';

const SalaryResults = (props) => {
  const {
    handleSubmitLoading,
    activeJob,
    posVal,
    locVal,
    conVal,
    shoVal,
    loading,
    isEarlyClick,
  } = props;

  if (activeJob && posVal !== '' && locVal !== '') {
    // let salaryLow = conVal === 'full-time' && shoVal === 'large' ? activeJob.salaryLow : conVal === 'full-time' && shoVal === 'small' ?
    let salaryLow, salaryHigh;

    if (conVal === 'freelance') {
      if (shoVal === 'small') {
        salaryLow = activeJob.smStudioFreelanceLow;
        salaryHigh = activeJob.smStudioFreelanceHigh;
      } else {
        salaryLow = activeJob.freelanceLow;
        salaryHigh = activeJob.freelanceHigh;
      }
    } else {
      if (shoVal === 'small') {
        salaryLow = activeJob.smStudioSalaryLow;
        salaryHigh = activeJob.smStudioSalaryHigh;
      } else {
        salaryLow = activeJob.salaryLow;
        salaryHigh = activeJob.salaryHigh;
      }
    }

    return (
      <Fade when={!handleSubmitLoading} duration={1500} wait={100}>
        <div className={`${styles.resultsContainer} ${styles.salaryArea}`}>
          <div className={styles.numberContainer}>
            <Typography variant="h3" className={styles.resultValue}>
              $
              <NumericLabel params={{ commafy: true }}>
                {parseInt(salaryLow)}
              </NumericLabel>
              <span className={styles.resultSmall}>
                {conVal === 'freelance' ? '/hr' : '/yr'}
              </span>
            </Typography>
            <Typography variant="body1" className={styles.rangeDesc}>
              {activeJob.salaryRangeLowDesc}
            </Typography>
          </div>
          <p className={`${styles.salaryResultText} ${styles.salaryDash}`}>
            &ndash;
          </p>
          <div className={styles.numberContainer}>
            <Typography variant="h3" className={styles.resultValue}>
              $
              <NumericLabel params={{ commafy: true }}>
                {parseInt(salaryHigh)}
              </NumericLabel>
              <span className={styles.resultSmall}>
                {conVal === 'freelance' ? '/hr' : '/yr'}
              </span>
            </Typography>
            <Typography variant="body1" className={styles.rangeDesc}>
              {activeJob.salaryRangeHighDesc}
            </Typography>
          </div>
        </div>
      </Fade>
    );
  } else {
    return null;
    // return isEarlyClick ? (
    //   <div
    //     className={
    //       !loading
    //         ? `${styles.flicker} ${styles.salaryArea}`
    //         : `${styles.salaryArea}`
    //     }
    //   >
    //     <Typography variant="body1">
    //       Select a position and a location!
    //     </Typography>
    //   </div>
    // ) : (
    //   <div
    //     className={
    //       !loading
    //         ? `${styles.textFocusIn} ${styles.salaryArea}`
    //         : `${styles.salaryArea}`
    //     }
    //   >
    //     <Typography variant="body1">
    //       Select both a Job and a Location to see the estimated salary rages.
    //     </Typography>
    //   </div>
    // );
  }
};

export default SalaryResults;
