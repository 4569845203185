import React from 'react';
import * as styles from '../styles/app.module.scss';
import { FormControl, Select, MenuItem, InputBase } from '@material-ui/core';

const ShopSelect = (props) => {
  const { shops, onShopChange, shopVal } = props;

  const handleChange = (e) => {
    onShopChange(e.target.value);
  };

  return (
    <>
      <div className={`${styles.selectWrapper} ${styles.posWrapper}`}>
        <FormControl className={styles.formWrap}>
          <Select
            className={styles.selectBox}
            value={shopVal}
            onChange={(e) => handleChange(e)}
            displayEmpty={true}
            variant="standard"
            input={
              <InputBase
                classes={{ input: `${styles.select} ${styles.sizSelect}` }}
              />
            }
            IconComponent={() => (
              <img
                style={{ width: '14px' }}
                src="/images/arrow-select-down.svg"
              />
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  border: '3px solid black',
                  padding: '0px',
                  borderRadius: '0px',
                  background: 'white',
                },
              },
              MenuListProps: {
                style: {
                  padding: '0px',
                  borderRadius: '0px',
                  background: 'white',
                },
              },
            }}
          >
            <MenuItem value="" disabled className={styles.popoverItem}>
              Choose a company
            </MenuItem>
            {shops.map((contract) => (
              <MenuItem
                key={contract.id}
                value={contract.id}
                className={styles.popoverItem}
              >
                {contract.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default ShopSelect;
