// extracted by mini-css-extract-plugin
export var heading = "svgs-module--heading--16b34";
export var infoIcon = "svgs-module--infoIcon--b44fb";
export var infoTooltip = "svgs-module--infoTooltip--7cc7c";
export var modalButton = "svgs-module--modalButton--abd61";
export var modalCloseIcon = "svgs-module--modalCloseIcon--30eab";
export var modalHeadline = "svgs-module--modalHeadline--226d5";
export var modalInner = "svgs-module--modalInner--7bb4f";
export var modalStyles = "svgs-module--modalStyles--82255";
export var subhead = "svgs-module--subhead--30292";
export var textCenter = "svgs-module--textCenter--f6c43";
export var tooltipCopy = "svgs-module--tooltipCopy--38f5a";