import React, { useState, useRef, useMemo, useEffect } from 'react';
import Layout from '../components/layout/layout';
import TopBar from '../components/layout/topBar';
import { useStaticQuery, graphql } from 'gatsby';
import SVGS from '../salaries/components/svgs';
import PositionSelect from '../salaries/components/positionSelect';
import ContractSelect from '../salaries/components/contractSelect';
import ShopSelect from '../salaries/components/shopSelect';
import LocationSelect from '../salaries/components/locationSelect';
import JobDetails from '../salaries/components/jobDetails';
import SalaryResults from '../salaries/components/salaryResults';
import LoadingScreen from '../salaries/components/loadingScreen';
import Footer from '../components/footer';
import MinimalFooter from '../minimalComponents/footer';
import { Button, Grid, Popover, Box, Typography } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import * as minimalStyles from '../salaries/styles/app.module.scss';
import * as playfulStyles from '../salaries/styles/appPlayful.module.scss';
import * as topBarStyles from '../salaries/styles/svgs.module.scss';
import { LayoutProvider, useLayoutContext } from '../contexts/layoutContext';
// import scripts from '../../scripts/getData';
import useIsClient from '../hooks/use-is-client';
import _salariesData from '../data/default-salaries.json';
import playfulTheme from '../themes/playfulTheme';
import minimalTheme from '../themes/minimalTheme';
import '../salaries/styles/svgs.scss';

import newSalariesData from '../../data/positions.json';

const isProd =
  typeof window !== 'undefined' &&
  window.location.href.indexOf('thesupply.com') > -1;

const SalariesPage = () => {
  return (
    <LayoutProvider>
      <StylesProvider injectFirst>
        <SalariesContent />
      </StylesProvider>
    </LayoutProvider>
  );
};

const SalariesContent = () => {
  const wrapperRef = useRef(null);

  //
  // hooks
  //

  const { isMinimal, firstLoadIn } = useLayoutContext();
  const { isClient, key } = useIsClient();

  //
  // input state
  //

  const [selectedContractValue, setSelectedContractValue] = useState(
    'full-time'
  );
  const [selectedShopValue, setSelectedShopValue] = useState('large');
  const [selectedPositionValue, setSelectedPositionValue] = useState('');
  const [selectedLocationValue, setSelectedLocationValue] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);

  //
  // get footer data
  //

  const { contentfulFooter } = useStaticQuery(graphql`
    query FooterQuery {
      contentfulFooter {
        logo {
          file {
            url
          }
        }
        logoCaption
        footerLinks {
          id
          linkText
          linkUrl
        }
      }
    }
  `);

  //
  // parse jobs data
  //

  // const [newSalariesData, setNewSalariesData] = useState(null);

  const titles = useMemo(() => {
    return newSalariesData?.tabs.map((s, i) => ({
      id: i,
      jobTitle: s.title,
      slug: s.id,
    }));
    // }, [newSalariesData]);
  }, []);

  // useEffect(() => {
  //   scripts.getContent().then((data) => {
  //     console.log('getContent', data);
  //     setNewSalariesData(data);
  //   });
  // }, []);

  const sortedJobs = useMemo(() => {
    let sorted = null;

    if (selectedPositionValue) {
      const posOptions = newSalariesData.data.filter(
        (d) => d.position === selectedPositionValue
      )[0]?.options;

      if (posOptions) {
        if (selectedLocationValue) {
          sorted = posOptions.filter(
            (item, i) => selectedLocationValue === item.locationSlug
          );
        } else {
          const useLocation = posOptions[0].locationSlug;
          sorted = posOptions.filter((v, i) => v.locationSlug === useLocation);
          // sorted = posOptions.filter(
          //   (v, i, a) => a.findIndex((v2) => v2.jobLevel === v.jobLevel) === i
          // );
        }
      }
    }

    if (sorted) {
      sorted.sort((a, b) => parseFloat(a.salaryLow) - parseFloat(b.salaryLow));
    }

    return sorted;
  }, [selectedPositionValue, selectedLocationValue]);

  const activeJobItem = useMemo(() => {
    return sortedJobs && sortedJobs[activeIndex];
  }, [sortedJobs, activeIndex]);

  //
  // popover hooks
  //

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    console.log('handlePopoverClose');
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  //
  // styles
  //

  const styles = isMinimal ? minimalStyles : playfulStyles;

  return (
    <Layout
      key={key}
      isMinimal={isMinimal}
      theme={isMinimal ? minimalTheme : playfulTheme}
    >
      <TopBar tickerId="731db781-9fb5-5391-98f4-6d0224172246" />

      {!newSalariesData || !titles ? (
        <LoadingScreen loading={true} />
      ) : (
        <div className={styles.wrapper} ref={wrapperRef}>
          <div className={styles.app}>
            <div className="topBar">
              <Button
                href="/"
                variant="contained"
                color="primary"
                className={styles.backBtn}
              >
                <img src="/images/arrow-back.svg" />
              </Button>
              <div className={topBarStyles.heading}>
                <Typography
                  variant="h3"
                  component="h1"
                  className={styles.title}
                >
                  Industry Salaries
                </Typography>
                <Typography variant="body1">
                  Thoughts on salary ranges we see in our realm of talent
                </Typography>
              </div>
              {/* <div className={styles.container}>
              </div> */}
              {/* <SVGS /> */}

              <div
                className={topBarStyles.infoIcon}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <img
                  src="/images/info-icon.svg"
                  alt="hover for more info"
                  className={topBarStyles.infoTooltip}
                />
              </div>
              <Popover
                id="mouse-over-popover"
                style={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                PaperProps={{
                  style: { maxWidth: '600px', textAlign: 'center' },
                }}
              >
                <Box p={4}>
                  <Typography>
                    Hey, thanks for stopping by. This is just a quick little
                    tool that gives some rough salary ranges in the
                    industry/world we are familiar with. We get asked about this
                    all the time, figured we’d put it in one simple place. There
                    are so many variables to salaries as you know, so just a
                    guideline. We will be adjusting this as time goes on,
                    working on adding some new filters (small shop vs massive
                    company, freelance rate, etc), feel free to send a note with
                    ideas and thoughts.
                  </Typography>
                </Box>
              </Popover>
            </div>
            <div className={styles.mainArea}>
              {/* <Popover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                style={{ marginTop: '-20px' }}
              >
                <Box className={styles.popoverBox}>
                  <Typography variant="caption">
                    Why would you click that? Gender and diversity shouldn't
                    affect pay at all, right?
                  </Typography>
                </Box>
              </Popover> */}

              <div className={`${styles.textCenter} ${styles.chooseWrapper}`}>
                <div className={styles.row}>
                  <div className={styles.rowItem}>
                    <Typography variant="h5" className={styles.sentence}>
                      I'm curious what a
                    </Typography>
                  </div>
                </div>

                <div className={styles.row}>
                  <div
                    className={styles.rowItem}
                    style={{ transform: isMinimal ? 'none' : 'rotate(-1deg)' }}
                  >
                    <ContractSelect
                      contracts={[
                        { id: 'full-time', title: 'Full-time' },
                        { id: 'freelance', title: 'Freelance' },
                      ]}
                      onContractChange={(val) => setSelectedContractValue(val)}
                      contractVal={selectedContractValue}
                    />
                  </div>
                  <div
                    className={styles.rowItem}
                    style={{ transform: isMinimal ? 'none' : 'rotate(2deg)' }}
                  >
                    <PositionSelect
                      titles={titles}
                      onPositionChange={(val) => setSelectedPositionValue(val)}
                      posVal={selectedPositionValue}
                    />
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.rowItem}>
                    <Typography variant="h5" className={styles.sentence}>
                      should make at a
                    </Typography>
                  </div>
                </div>

                <div className={styles.row}>
                  <div
                    className={styles.rowItem}
                    style={{ transform: isMinimal ? 'none' : 'rotate(1deg)' }}
                  >
                    <ShopSelect
                      shops={[
                        { id: 'large', title: 'Big Company' },
                        { id: 'small', title: 'Small Studio' },
                      ]}
                      onShopChange={(val) => setSelectedShopValue(val)}
                      shopVal={selectedShopValue}
                    />
                  </div>
                  <div className={styles.rowItem}>
                    <Typography variant="h5" className={styles.sentence}>
                      in
                    </Typography>
                  </div>
                  <div
                    className={styles.rowItem}
                    style={{ transform: isMinimal ? 'none' : 'rotate(-2deg)' }}
                  >
                    <LocationSelect
                      locations={newSalariesData.locations}
                      locVal={selectedLocationValue}
                      onLocationChange={(val) => setSelectedLocationValue(val)}
                    />
                  </div>
                </div>

                {/* <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={4} className={styles.posContainer}>
                      <PositionSelect
                        titles={titles}
                        onPositionChange={(val) =>
                          setSelectedPositionValue(val)
                        }
                        posVal={selectedPositionValue}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={styles.selectContainer}
                    >
                      <LocationSelect
                        locations={newSalariesData.locations}
                        locVal={selectedLocationValue}
                        onLocationChange={(val) =>
                          setSelectedLocationValue(val)
                        }
                      />
                    </Grid>
                  </Grid> */}
                {/* <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={4} className={styles.posContainer}>
                      <ContractSelect
                        contracts={[
                          { id: 'full-time', title: 'full-time' },
                          { id: 'freelance', title: 'freelance' },
                        ]}
                        onContractChange={(val) =>
                          setSelectedContractValue(val)
                        }
                        contractVal={selectedContractValue}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={styles.selectContainer}
                    >
                      <ShopSelect
                        shops={[
                          { id: 'large', title: 'Big Company' },
                          { id: 'small', title: 'Small Studio' },
                        ]}
                        onShopChange={(val) => setSelectedShopValue(val)}
                        shopVal={selectedShopValue}
                      />
                    </Grid>
                  </Grid> */}
              </div>

              <div className={`${styles.jobContainer} ${styles.contain}`}>
                <SalaryResults
                  activeJob={activeJobItem}
                  posVal={selectedPositionValue}
                  locVal={selectedLocationValue}
                  conVal={selectedContractValue}
                  shoVal={selectedShopValue}
                />
                <JobDetails
                  handleJobLevelSelect={(val) => setActiveIndex(val)}
                  sortedJobsArr={sortedJobs || newSalariesData.defaults}
                  activeIndex={activeIndex}
                  posVal={selectedPositionValue}
                  locVal={selectedLocationValue}
                  activeJobItem={activeJobItem}
                  isMinimal={isMinimal}
                />
              </div>
            </div>
          </div>
          {isMinimal ? (
            <MinimalFooter footer={contentfulFooter} />
          ) : (
            <Footer footer={contentfulFooter} />
          )}
        </div>
      )}
    </Layout>
  );
};

export default SalariesPage;
