// extracted by mini-css-extract-plugin
export var flicker = "salaryResults-module--flicker--0871f";
export var numberContainer = "salaryResults-module--numberContainer--dc4ab";
export var rangeDesc = "salaryResults-module--rangeDesc--fc9cc";
export var resultSmall = "salaryResults-module--resultSmall--64dcb";
export var resultValue = "salaryResults-module--resultValue--4dcb6";
export var resultsContainer = "salaryResults-module--resultsContainer--bb6b1";
export var salaryArea = "salaryResults-module--salaryArea--9fcd4";
export var salaryDash = "salaryResults-module--salaryDash--7546f";
export var salaryResultText = "salaryResults-module--salaryResultText--1202b";
export var textFocusFadeIn = "salaryResults-module--textFocusFadeIn--b698a";
export var textFocusIn = "salaryResults-module--text-focus-in--8eefc";