import React from 'react';
import * as styles from '../styles/app.module.scss';
import { FormControl, Select, MenuItem, InputBase } from '@material-ui/core';

const LocationSelect = (props) => {
  const { locations, onLocationChange, locVal, isEarlyClick } = props;

  const handleChange = (e) => {
    onLocationChange(e.target.value);
  };

  return (
    <>
      <div
        className={
          isEarlyClick
            ? `${styles.pulsate} ${styles.selectWrapper} ${styles.locWrapper}`
            : `${styles.selectWrapper} ${styles.locWrapper}`
        }
      >
        <FormControl className={styles.formWrap}>
          <Select
            className={styles.selectBox}
            value={locVal}
            onChange={(e) => handleChange(e)}
            displayEmpty={true}
            variant="standard"
            input={
              <InputBase
                classes={{ input: `${styles.select} ${styles.citSelect}` }}
              />
            }
            IconComponent={() => (
              <img
                style={{ width: '14px' }}
                src="/images/arrow-select-down.svg"
              />
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  border: '3px solid black',
                  padding: '0px',
                  borderRadius: '0px',
                  background: 'white',
                },
              },
              MenuListProps: {
                style: {
                  padding: '0px',
                  borderRadius: '0px',
                  background: 'white',
                },
              },
            }}
          >
            <MenuItem value="" disabled className={styles.popoverItem}>
              Choose a location
            </MenuItem>
            {locations.map((location, index) => (
              <MenuItem
                key={`location-${index}`}
                value={location.locationSlug}
                className={styles.popoverItem}
              >
                {location.location}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default LocationSelect;
